<template>
    <div class="holder">
        <div class="mainPolicyDiv">
            <p>
                <span>
                    <label class="numbers">3</label>
                    <label class="clientDetails">Policy details</label>
                </span>

                <span style="float:right">
                    <label class="percentageCompleted">60% completed</label>
                </span>
            </p>
            <p>
                <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="60"></el-progress>
            </p>

            <div class="item">
                <h1 class="Title">What are the policy details?</h1>
            </div>

            <el-form label-position="top" ref="ruleFormUserSelection" :model="UserSelection" :rules="rules">

                <el-form-item v-if="Customers !== null && Customers.isLifeTwo" for="userCustomer" class="labels" label="Select Customer" prop="selectedCustomerType">
                    <el-select v-model="UserSelection.selectedCustomerType" placeholder="Please select">
                        <el-option :value="0" :label="Customers.life1FirstName + ' ' + Customers.life1LastName">{{Customers.life1FirstName + " " + Customers.life1LastName}}</el-option>
                        <el-option v-if="Customers.isLifeTwo" :value="1" :label="Customers.life2FirstName + ' ' + Customers.life2LastName">{{Customers.life2FirstName + " " + Customers.life2LastName}}</el-option>
                        <el-option v-if="Customers.isLifeTwo && UserSelection.tool.toolReference !== 'IPPro'" :value="2" :label="Customers.life1FirstName + ' ' + Customers.life1LastName + ' & ' + Customers.life2FirstName + ' ' + Customers.life2LastName">{{Customers.life1FirstName + ' ' + Customers.life1LastName + ' & ' + Customers.life2FirstName + " " + Customers.life2LastName}}</el-option>
                    </el-select>
                </el-form-item>
                <div>
                    <el-form-item v-if="IsTermAssurance" label="Is this a decreasing policy?">
                        <el-select v-model="DecreasingPolicy">
                            <el-option label="Yes" :value="true"></el-option>
                            <el-option label="No" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div style="display: inline-flex;">
                    <el-form-item label="Term length" prop="policyTerm">
                        <el-input :min="5" step="1" :integer="true" style="width: 186px;" type="age" v-model.number="UserSelection.policyTerm"></el-input>
                    </el-form-item>

                    <el-form-item label="To age" class="labels" style="padding-left:10px;" prop="toAge" v-if="(!IsTermAssurance || !DecreasingPolicy) && !(PolicyType !== '' && PolicyType === 'Joint')">
                        <el-input :min="21" step="1" :integer="true" :max="70" style=" width: 166px;" type="age" v-model.number="UserSelection.toAge"></el-input>
                    </el-form-item>
                </div>

                <div>
                    <div v-if="IsTermAssurance || (UserSelection.tool.toolReference == 'FIBPro' || UserSelection.tool.toolReference == 'FIBCIPro') || (UserSelection.tool.toolReference == 'KeyPersonPro' || UserSelection.tool.toolReference == 'KeyPersonCIPro')">
                        <el-form-item label="Life cover amount" class="labels" prop="termBenefitAmount">
                            <el-input v-model.number="UserSelection.termBenefitAmount" type="number" min="5" max="99999.99" id="termBenefitAmount">
                                <template #prefix>
                                    £
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item :label="UserSelection.tool.toolReference == 'FIBPro' || UserSelection.tool.toolReference == 'FIBCIPro' ? 'Annual Critical illness cover amount' : 'Critical illness cover amount'" prop="termCriticalIllnessCoverAmount">
                            <el-input v-model.number="UserSelection.termCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termBenefitAmount">
                                <template #prefix>
                                    £
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="UserSelection.tool.toolReference == 'FIBPro' || UserSelection.tool.toolReference == 'FIBCIPro' ? 'Annual or earlier CI cover amount' : 'Life or earlier CI cover amount'" prop="termLifeOrEarlierCriticalIllnessCoverAmount">
                            <el-input v-model.number="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termLifeOrEarlierCriticalIllnessCoverAmount">
                                <template #prefix>
                                    £
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="Waiver of premium" class="labels" v-if="!['IPPro'].includes(UserSelection.tool.toolReference)" prop="waiverOfPremium">
                            <el-select id="WaiverOfPremium" placeholder="Please select" v-model="UserSelection.waiverOfPremium">
                                <el-option label="None" value="None"></el-option>
                                <el-option label="First Life" value="FirstLife"></el-option>
                                <el-option label="Second Life" value="SecondLife" v-if="UserSelection.selectedCustomerType == 2"></el-option>
                                <el-option label="Both Lives" value="BothLives" v-if="UserSelection.selectedCustomerType == 2"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div>
                    <div v-if="UserSelection.tool.toolReference == 'IPPro'">
                        <el-form-item class="labels" label="Benefit Basis" prop="ipBenefitBasis">
                            <el-select v-model="UserSelection.ipBenefitBasis" placeholder="Please select" style="width: 186px;">
                                <el-option label="Monthly" value="Monthly"></el-option>
                                <el-option label="Maximum" value="Maximum"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="labels" label="Monthly benefit amount" v-if="UserSelection.ipBenefitBasis === 'Monthly'" prop="ipBenefitAmount">
                            <el-input v-model.number="UserSelection.ipBenefitAmount">
                                <template #prefix>
                                    £
                                </template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>

                <div v-if="ShouldAskForChildren && (this.UserSelection.tool.toolReference !== 'FIBCIPro' || this.UserSelection.tool.toolReference !== 'FIBPro')">
                    <div v-for="(child,childindex) in UserSelection.children" :key="childindex">
                        <div style="margin-bottom:10px;">
                            <label style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px;color: #0F172A;"> Child {{ childindex + 1 }} </label>
                            <el-link type="primary" @click="RemoveChild(child)" style="float:right;font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;"> Remove </el-link>
                        </div>

                        <el-form-item for="'Child + childindex + 1 + 'DOB'" class="labels" label="Child age">

                            <!--Child {{ childindex + 1 }} DOB-->
                            <age-control v-model="child.dateOfBirth" />
                        </el-form-item>

                        <el-form-item for="'Child'+ childindex + 1 + 'Gender'" label="Child gender">

                            <!--Child {{ childindex + 1 }} Gender-->
                            <el-select placeholder="Please select" v-bind:id="'Child' + childindex + 1 + 'Gender'" v-model="child.gender">
                                <el-option label="Male" value="Male"></el-option>
                                <el-option label="Female" value="Female"></el-option>
                            </el-select>
                        </el-form-item>

                    </div>

                    <div>
                        <el-link type="primary" @click="AddChild()" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;"> {{ UserSelection.children.length == 0 ? 'Add child' :  'Add another child' }} </el-link>
                    </div>
                </div>

                <el-form-item class="btnNextPosition" v-if="!IsCreatingJourney">
                    <el-button type="default" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span>Back</el-button>
                    <el-button type="success" v-on:click.stop="Proceed('ruleFormUserSelection')">Next</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="summary" v-if="Customers != null">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null && UserSelection.selectedCustomerType != null">
                <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>

            <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Quality of plans</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>
    </div>
</template>
<style scoped>
    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .mainPolicyDiv {
            margin-left: -250px;
        }

        .summary {
            left: -250px;
        }
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        width: 232px;
        height: 572px;
        top: 0;
        right: 150px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .el-form--label-top .el-form-item__label {
        float: none;
        display: inline-block;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #0F172A;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    /*.el-form-item__label {
            font-family: 'Inter' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 24px !important;
            padding: 0px !important;
        }*/

    .el-input__inner {
        color: black;
    }

    /*.el-progress__text {
            display: none !important;
        }

        .el-progress-bar__inner {
            padding-top: 5px;
            color: #1E228C
        }
    */
    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .clientDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
    }

    /*.el-progress--without-text {
            display: none !important;
        }*/

    .el-input {
        width: 366px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-button {
        display: inline;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 167px;
        height: 44px;
        border-radius: 8px;
        background: #059669;
        /*border-color: #059669;*/
    }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669;
        border-radius: 8px;
        /*border-color: #059669;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE;
        border-radius: 8px;
        /*border-color: #E7EAEE;*/
        font-style: normal;
        color: #A0ABBB;
    }

    .el-button--success {
        color: #fff;
        background: #059669 !important;
        /*border-color: #059669;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px;
        /*border-color: #E7EAEE;*/
        font-style: normal;
    }

    .btnParaCover {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        position: static;
        width: 367px;
        height: 44px;
        left: 82px;
        top: 935px;
    }

    .datePickerField {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        height: 48px;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .mainPolicyDiv {
        padding: 82px;
        position: absolute;
        width: 531px;
        left: 0;
        top: 0;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .Title {
        width: 366px;
        height: 20px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .TitleInputs {
        width: 166px;
        height: 48px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .labels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        color: #0F172A;
    }

    .input {
        padding: 10px 12px 10px 16px;
        width: 366px !important;
        height: 48px !important;
        left: 82px;
        top: 229px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 56px;
        display: flex;
        align-items: center;
    }

    .DropdownControl {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        position: absolute;
        width: 166px !important;
        height: 44px !important;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .btnNext {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 167px;
        height: 44px;
        left: 200px;
        top: 0px;
        background: #059669;
        border-radius: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 14px;
        float: right;
    }
</style>
<script>
    //import DateControl from "./DateControl.vue";
    import $ from 'jquery';
    //import NumberInput from './NumberInput.vue';
    import AgeControl from './AgeControl.vue';
    import helper from './HelperMethods.vue';
    export default {
        components: { /*DateControl, NumberInput,*/ AgeControl },
        name: 'PolicyDetails',
        props: ["userSelection", "userDetails"],
        data: function () {
            var thisControl = this;
            return {
                helper: helper,
                IsCreatingJourney: false,
                showingSmokerDefinitions: false,
                Customers: [],
                ruleFormUserSelection: {
                    selectedCustomerType: '',
                    policyTerm: null,
                    toAge: null,
                    ipBenefitBasis: '',
                    ipBenefitAmount: '',
                    waiverOfPremium: '',
                },
                rules: {
                    selectedCustomerType: [
                        { required: true, message: 'Please select customer type', trigger: 'change' }
                    ],
                    policyTerm: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                    if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                        callback(new Error("Required if to age is not supplied."))

                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("Term length must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    toAge: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                    if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                        callback(new Error("Required if term length is not supplied."))

                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("To age must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    ipBenefitBasis: [
                        { required: true, message: 'Please select Benefit basis', trigger: 'change' }
                    ],
                    ipBenefitAmount: [
                        {
                            validator: function (rule, value, callback) {

                                if (thisControl.IsTermAssurance)
                                    callback();//This is not required as Term Assurance;

                                if (thisControl.UserSelection.ipBenefitBasis !== 'Monthly') {
                                    callback();//This is not required;
                                }
                                if (thisControl.UserSelection.ipBenefitAmount === null || thisControl.UserSelection.ipBenefitAmount === "") {
                                    callback(new Error("Monthly benefit amount is required."))
                                }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("life cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                        //{ min: 5, max: 99999.99, message: 'Monthly benefit amount should be 5 to 99999.99', trigger: 'change' }
                        //{  type: 'number', message: 'Monthly benefit amount should be 5 to 99999.99', trigger: 'change' }
                    ],
                    termBenefitAmount: [
                        {
                            validator: function (rule, value, callback) {

                                if (!thisControl.IsTermAssurance)
                                    callback();//This is not required as not Term Assurance;

                                if (thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "") {
                                    if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "") {
                                        if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "") {
                                            callback(new Error("At least one of Life, Critical Illness or life or earlier CI cover amount is required."))
                                        }
                                    }
                                }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("life cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    termCriticalIllnessCoverAmount: [
                        {
                            validator: function (rule, value, callback) {
                                if (!thisControl.IsTermAssurance)
                                    callback();//This is not required as not Term Assurance;
                                if (thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "") {
                                    if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "") {
                                        if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "") {
                                            callback(new Error("At least one of Life, Critical Illness or life or earlier CI cover amount is required."))
                                        }
                                    }
                                }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("Critical illness cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    termLifeOrEarlierCriticalIllnessCoverAmount: [
                        {
                            validator: function (rule, value, callback) {
                                if (!thisControl.IsTermAssurance)
                                    callback();//This is not required as not Term Assurance;
                                if (thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "") {
                                    if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "") {
                                        if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "") {
                                            callback(new Error("At least one of Life, Critical Illness or life or earlier CI cover amount is required."))
                                        }
                                    }
                                }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error(" Life or earlier CI cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    waiverOfPremium: [
                        { required: false, message: 'Please select Waiver of premium', trigger: 'change' }
                    ]
                }
            }
        },
        created: function () {
            //if (this.UserSelection.primaryClient === null) {
            //    this.UserSelection.primaryClient = { classification: "Male", dateOfBirth: '1990-01-01' };
            //}
            if (this.UserSelection.tool.toolReference === 'FIBCIPro' || this.UserSelection.tool.toolReference === 'FIBPro')
                this.UserSelection.decreasingPolicy = true;

            if (this.UserSelection.primaryClient === null)
                this.UserSelection.primaryClient = { classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed' };

            if (this.UserSelection.selectedCustomerType == null) {
                this.UserSelection.primaryClient = { classification: "", dateOfBirth: '' };
            }
            else
                this.UserSelection.selectedCustomerType = parseInt(this.UserSelection.selectedCustomerType);

            if (this.UserSelection.children === null) {
                this.UserSelection.children = [];
                this.UserSelection.childNames = [];
            }

            this.GetCustomer();

        },
        mounted: function () {
            this.UserSelection.pgpLinkedAnalysisReference  = null;
            this.UserSelection.umeLinkedAnalysisReference  = null;
        },
        methods: {
            Proceed: function (formName) {
                var thisControl = this;
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        if (thisControl.UserSelection.tool.toolReference !== "IPPro")
                            thisControl.CheckTool();

                        thisControl.$emit('policy-details-added');
                    }
                    else {
                        alert("Please correct errors before continuing.")
                    }
                });
            },
            GetSmokerToolTip: function () {
                var tooTips =
                    ["Current smoker - Any adult who smokes currently on a regular basis.",
                        "Ex-regular smokers - Any adult that has smoked regularly in the past, but not currently.",
                        "Non-smokers - Any adult that has never smoked on a regular basis."];

                return tooTips.reduce(function (sofar, tooTip) { return sofar + "\n" + tooTip; }, "").trim();
            },
            AddChild: function () {
                this.UserSelection.children.push({
                    gender: "Male",
                    dateOfBirth: '2015-01-01'
                });
                this.UserSelection.childNames.push("");
            },
            RemoveChild: function (child) {
                this.UserSelection.childNames.splice(this.UserSelection.children.indexOf(child), 1);
                this.UserSelection.children.splice(this.UserSelection.children.indexOf(child), 1);
            },
            CheckTool: function () {
                var hasCI = (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null || this.UserSelection.termCriticalIllnessCoverAmount !== null);
                var isMP = (this.UserSelection.tool.toolReference === "FIBPro" ? true : this.UserSelection.tool.toolReference === "FIBCIPro" ? true : this.DecreasingPolicy);

                let selectedTool = this.UserSelection.tool.toolReference;

                if (selectedTool === "FIBPro" || selectedTool === 'FIBCIPro') {
                    if (hasCI) {
                        selectedTool = 'FIBCIPro';
                    }
                    else {
                        selectedTool = 'FIBPro'
                    }
                } else if (selectedTool === 'KeyPersonPro' || selectedTool === 'KeyPersonCIPro') {
                    if (hasCI) {
                        selectedTool = 'KeyPersonCIPro';
                    } else {
                        selectedTool = 'KeyPersonPro';
                    }
                } else if (hasCI && isMP) {
                    selectedTool = 'MPCIPro';
                }
                else if (hasCI && !isMP) {
                    selectedTool = 'CIPlus';
                }
                else if (!hasCI && isMP) {
                    selectedTool = 'MPPro';
                }
                else if (!hasCI && !isMP) {
                    selectedTool = 'LifePro';
                }

                if (
                    (this.UserSelection.termCriticalIllnessCoverAmount === null || this.UserSelection.termCriticalIllnessCoverAmount <= 0)
                    && (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount <= 0)
                ) {
                    this.UserSelection.termTotalPermanentDisability = false;
                }

                this.$emit("tool-updated", selectedTool);
            },
            GetCustomer: function () {
                var thisControl = this;
                this.Customers = null;

                $.ajax({
                    type: 'GET',
                    url: '/Customer/' + thisControl.UserSelection.customerReference,
                    data: {},
                    success: function (customer) {
                        if (customer.customerReference === null)
                            customer.customerReference = thisControl.UserSelection.customerReference;

                        thisControl.Customers = customer;

                        if (thisControl.Customers.life1DateOfBirth !== null)
                            thisControl.Customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                        if (thisControl.Customers.life2DateOfBirth !== null)
                            thisControl.Customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                        if (!customer.isLifeTwo)
                            thisControl.UserSelection.selectedCustomerType = 0;
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$message.error("Error updating customer: " + xhr.responseText);
                    }
                });

                return this.Customers;
            },
            CheckValidation: function () {
                if ((this.UserSelection.policyTerm === null || this.UserSelection.policyTerm === "") && (this.UserSelection.toAge === null || this.UserSelection.toAge === ""))
                    return true;

                if (this.UserSelection.ipBenefitBasis === null || this.UserSelection.ipBenefitBasis === "")
                    return true;

                if (this.UserSelection.ipBenefitBasis === "Monthly") {
                    if (this.UserSelection.ipBenefitAmount === null || this.UserSelection.ipBenefitAmount === "" || this.UserSelection.ipBenefitAmount === "0")
                        return true;
                }

                return false;
            },
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            CanProceed: function () {
                return (this.UserSelection.toAge > 0 || this.UserSelection.policyTerm > 0) || (!this.IsTermAssurance && this.UserSelection.ipBenefitBasis === "Maximum") || (!this.IsTermAssurance && this.UserSelection.ipBenefitBasis === "Monthly") && (this.UserSelection.ipBenefitAmount !== '' || this.UserSelection.ipBenefitAmount !== '0');
            },
            CalculatedAmountAssured: {
                get: function () {
                    if (this.IsTermAssurance) {
                        if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null && this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount > 0)
                            return this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount;
                        if (this.UserSelection.termCriticalIllnessCoverAmount !== null && this.UserSelection.termCriticalIllnessCoverAmount > 0)
                            return this.UserSelection.termCriticalIllnessCoverAmount;
                        if (this.UserSelection.termBenefitAmount !== null && this.UserSelection.termBenefitAmount > 0)
                            return this.UserSelection.termBenefitAmount;
                        return 0;
                    }
                    else {
                        //Income Protection
                        if (this.UserSelection.ipBenefitBasis === 'Monthly' && this.UserSelection.ipbenefitAmount !== null && this.UserSelection.ipbenefitAmount > 0)
                            return this.UserSelection.ipbenefitAmount;
                        return 0;
                    }
                }
            },
            IsTermAssurance: {
                get: function () {
                    var termAssuranceTools = ['MPCIPro', 'MPPro', 'CIPlus', 'LifePro'];

                    return termAssuranceTools.indexOf(this.UserSelection.tool.toolReference) > -1;
                }
            },
            ShouldAskForChildren: {
                get: function () {
                    return this.UserSelection.features.some(function (f) { return f.requiresChildrensDetails === true; });
                }
            },
            PrimaryClientGender: {
                get: function () {
                    //if (this.UserSelection.primaryClient.classification.startsWith("Male"))
                    //    return "Male";
                    //return "Female";
                    if (this.UserSelection.primaryClient.classification.startsWith("Male"))
                        return "Male";
                    else if (this.UserSelection.primaryClient.classification.startsWith("Female"))
                        return "Female";
                    else
                        return "";
                },
                set: function (value) {
                    this.UserSelection.primaryClient.classification = value + this.PrimaryClientSmokerStatus
                }
            },
            PrimaryClientSmokerStatus: {
                get: function () {
                    return this.UserSelection.primaryClient.classification.replace(this.PrimaryClientGender, "")
                },
                set: function (value) {
                    this.UserSelection.primaryClient.classification = this.PrimaryClientGender + value;
                }
            },
            SecondaryClientGender: {
                get: function () {
                    if (this.UserSelection.secondaryClient.classification.startsWith("Male"))
                        return "Male";
                    return "Female";
                },
                set: function (value) {
                    this.UserSelection.secondaryClient.classification = value + this.SecondaryClientSmokerStatus
                }
            },
            SecondaryClientSmokerStatus: {
                get: function () {
                    return this.UserSelection.secondaryClient.classification.replace(this.SecondaryClientGender, "")
                },
                set: function (value) {
                    this.UserSelection.secondaryClient.classification = this.SecondaryClientGender + value;
                }
            },
            PolicyType: {
                get: function () {
                    return this.UserSelection.secondaryClient === null ? "Single" : "Joint";
                },
                set: function (value) {

                    if (value == "Joint") {
                        this.UserSelection.secondaryClient = { classification: "Male", dateOfBirth: this.UserSelection.primaryClient.dateOfBirth }
                    }
                    else {
                        this.UserSelection.secondaryClient = null;
                    }
                }
            },
            DecreasingPolicy: {
                get: function () {
                    return this.UserSelection.tool.toolReference === "MPPro" || this.UserSelection.tool.toolReference === "MPCIPro";
                },
                set: function (value) {
                    this.UserSelection.decreasingPolicy = value;

                    if (this.UserSelection.tool.toolReference === "KeyPersonPro" || this.UserSelection.tool.toolReference === "KeyPersonCIPro")
                        return value;

                    if (value === true && (this.UserSelection.tool.toolReference === "MPPro" || this.UserSelection.tool.toolReference === "MPCIPro"))
                        return; //already set

                    if (value === false && (this.UserSelection.tool.toolReference !== "MPPro" && this.UserSelection.tool.toolReference !== "MPCIPro"))
                        return; //already set


                    if (value === false) {
                        if (this.UserSelection.tool.toolReference === "MPPro") {
                            if (this.UserSelection.tool.toolReference === "MPPro")
                                this.$emit("tool-updated", 'LifePro');
                            else
                                this.$emit("tool-updated", 'CIPlus');
                        }
                        else if (this.UserSelection.tool.toolReference === "FIBCIPro") {
                            if (this.UserSelection.tool.toolReference === "FIBCIPro") {
                                this.$emit("tool-updated", 'FIBPro');
                            }
                            else
                                this.$emit("tool-updated", 'FIBCIPro');
                        }
                        else if (this.UserSelection.tool.toolReference === "FIBPro") {
                            if (this.UserSelection.tool.toolReference === "FIBPro") {
                                this.$emit("tool-updated", 'FIBPro');
                            }
                            else
                                this.$emit("tool-updated", 'FIBCIPro');
                        }
                        else if (this.UserSelection.tool.toolReference === "KeyPersonCIPro") {
                            if (this.UserSelection.tool.toolReference === "KeyPersonCIPro") {
                                this.$emit("tool-updated", 'KeyPersonPro');
                            }
                            else
                                this.$emit("tool-updated", 'KeyPersonCIPro');
                        }
                        else if (this.UserSelection.tool.toolReference === "KeyPersonPro") {
                            if (this.UserSelection.tool.toolReference === "KeyPersonPro") {
                                this.$emit("tool-updated", 'KeyPersonPro');
                            }
                            else
                                this.$emit("tool-updated", 'KeyPersonCIPro');
                        }
                    }
                    if (value === true) {
                        if (this.UserSelection.tool.toolReference === "LifePro")
                            this.$emit("tool-updated", 'MPPro');
                        else
                            this.$emit("tool-updated", 'MPCIPro');
                    }
                }
            }
        },
        watch: {
            'CalculatedAmountAssured': function (newValue) {
                this.UserSelection.amountAssured = newValue;
            },
            'UserSelection.policyTerm': function (newValue) {
                if (newValue !== null)
                    this.UserSelection.toAge = null;
            },
            'UserSelection.toAge': function (newValue) {
                if (newValue !== null)
                    this.UserSelection.policyTerm = null;
            },
            'UserSelection.termBenefitAmount': function (newValue) {
                if (newValue !== null) {
                    if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null)
                        this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;

                    if (newValue == 0)
                        this.UserSelection.termBenefitAmount = null;
                }
                this.CheckTool();
            },
            'UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount': function (newValue) {
                if (newValue !== null) {
                    if (this.UserSelection.termBenefitAmount !== null)
                        this.UserSelection.termBenefitAmount = null;

                    if (this.UserSelection.termCriticalIllnessCoverAmount !== null)
                        this.UserSelection.termCriticalIllnessCoverAmount = null;

                    if (newValue == 0)
                        this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;
                }
                this.CheckTool();
                this.UserSelection.amountAssured = newValue;
            },
            'UserSelection.termCriticalIllnessCoverAmount': function (newValue) {
                if (newValue !== null) {
                    if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null)
                        this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;

                    if (newValue == 0)
                        this.UserSelection.termCriticalIllnessCoverAmount = null;
                }
                this.CheckTool();
            },
            "UserSelection.selectedCustomerType": function () {

                if (this.UserSelection.selectedCustomerType == 0 && this.Customers !== null && this.Customers !== undefined) {
                    this.PrimaryClientGender = this.Customers.life1Gender;
                    this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;

                    this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                    this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                    this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                    this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                    this.UserSelection.primaryClient.title = this.Customers.life1Title;
                    this.UserSelection.secondaryClient = null;
                }
                else if (this.UserSelection.selectedCustomerType == 1 && this.Customers !== null && this.Customers !== undefined) {
                    this.PrimaryClientGender = this.Customers.life2Gender;
                    this.PrimaryClientSmokerStatus = this.Customers.life2SmokingStatus;

                    this.UserSelection.primaryClient.dateOfBirth = this.Customers.life2DateOfBirth;
                    this.UserSelection.primaryClient.forename = this.Customers.life2FirstName;
                    this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.primaryClient.salary = this.Customers.life2Salary;
                    this.UserSelection.primaryClient.surname = this.Customers.life2LastName;
                    this.UserSelection.primaryClient.title = this.Customers.life2Title;
                    this.UserSelection.secondaryClient = null;
                }
                else if (this.UserSelection.selectedCustomerType == 2 && this.Customers !== null && this.Customers !== undefined) {
                    this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                    this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                    this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                    this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                    this.UserSelection.primaryClient.title = this.Customers.life1Title;
                    this.UserSelection.primaryClient.occupation = this.Customers.life1Occupation;
                    this.UserSelection.secondaryClient = {
                        dateOfBirth: this.Customers.life2DateOfBirth,
                        forename: this.Customers.life2FirstName,
                        personEmployedStatus: "Employed",
                        salary: this.Customers.life2Salary,
                        surname: this.Customers.life2LastName,
                        title: this.Customers.life2Title,
                        classification: 'Male'
                    };

                    this.PrimaryClientGender = this.Customers.life1Gender;
                    this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;
                    this.SecondaryClientGender = this.Customers.life2Gender;
                    this.SecondaryClientSmokerStatus = this.Customers.life2SmokingStatus;
                }
                this.UserSelection.waiverOfPremium = "None";
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
