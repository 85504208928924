<template>
    <div class="holder">
        <div class="mainPolicyDiv">
            <p>
                <span>
                    <label class="numbers">3</label>
                    <label class="clientDetails">Policy details</label>
                </span>

                <span style="float:right">
                    <label class="percentageCompleted">60% completed</label>
                </span>
            </p>
            <p>
                <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="60"></el-progress>
            </p>

            <div class="item">
                <h1 class="Title">What are the policy details?</h1>
            </div>

            <el-form label-position="top" ref="ruleFormUserSelection" :model="UserSelection" :rules="rules">

                <el-form-item v-if="Customers !== null && Customers.isLifeTwo" class="labels" label="Life assured" prop="selectedCustomerType">
                    <el-select v-model="UserSelection.selectedCustomerType" placeholder="Please select">
                        <el-option :value="0" :label="Customers.life1FirstName + ' ' + Customers.life1LastName">{{Customers.life1FirstName + " " + Customers.life1LastName}}</el-option>
                        <el-option v-if="Customers.isLifeTwo" :value="1" :label="Customers.life2FirstName + ' ' + Customers.life2LastName">{{Customers.life2FirstName + " " + Customers.life2LastName}}</el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Indexation" prop="ipIndexation">
                    <el-select v-model="UserSelection.ipIndexation" placeholder="Please select" style="width: 186px;">
                        <el-option label="Level" value="Level" />
                        <el-option label="RPI" value="RPI" />
                        <el-option label="3%" value="Three" />
                        <el-option label="5%" value="Five" />
                    </el-select>
                </el-form-item>

                <div style="display: inline-flex;">

                    <el-form-item label="Term length" prop="policyTerm">
                        <el-input :min="5" step="1" :integer="true" style="width: 186px;" type="age" v-model.number="UserSelection.policyTerm"></el-input>
                    </el-form-item>

                    <el-form-item label="To age" class="labels" style="padding-left:10px;" prop="toAge">
                        <el-input :min="21" step="1" :integer="true" :max="70" style=" width: 166px;" type="age" v-model.number="UserSelection.toAge"></el-input>
                    </el-form-item>
                </div>

                <el-form-item v-if="userDetails.defaultIntegration != 'UNDERWRITEME'" class="labels" label="Benefit Basis" prop="ipBenefitBasis">
                    <el-select v-model="UserSelection.ipBenefitBasis" placeholder="Please select" style="width: 186px;">
                        <el-option label="Monthly" value="Monthly"></el-option>
                        <el-option label="Maximum" value="Maximum"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="labels" label="Monthly benefit amount" v-if="UserSelection.ipBenefitBasis === 'Monthly'" prop="ipBenefitAmount">
                    <el-input v-model.number="UserSelection.ipBenefitAmount" type="number" min="5" max="99999.99" id="ipBenefitAmount"></el-input>
                </el-form-item>

                <el-form-item label="Initial deferred period" prop="ipInitialDeferredPeriod">
                    <el-select v-model="UserSelection.ipInitialDeferredPeriod" placeholder="Please select" style="width: 186px;">
                        <el-option label="1 day" :value="0"></el-option>
                        <el-option label="1 Week" :value="1"></el-option>
                        <el-option label="2 Weeks" :value="2"></el-option>
                        <el-option label="1 Month" :value="3"></el-option>
                        <el-option label="2 Month" :value="4"></el-option>
                        <el-option label="3 Month" :value="5"></el-option>
                        <el-option label="6 Month" :value="6"></el-option>
                        <el-option label="12 Month" :value="7"></el-option>
                        <el-option label="24 Months" :value="8"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Additional deferred period if dual deferred is required" v-if="UserSelection.ipInitialDeferredPeriod != 8 && UserSelection.ipBenefitBasis === 'Monthly'" prop="ipAdditionalDeferredPeriod" style="width: 356px !important;">
                    <el-select v-model="UserSelection.ipAdditionalDeferredPeriod" placeholder="Please select" style="width: 186px;">
                        <el-option label="N/A" :value="null"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 1" label="1 Week" :value="1"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 2" label="2 Weeks" :value="2"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 3" label="1 Month" :value="3"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 4" label="2 Month" :value="4"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 5" label="3 Month" :value="5"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 6" label="6 Month" :value="6"></el-option>
                        <el-option v-if="UserSelection.ipInitialDeferredPeriod < 7" label="12 Month" :value="7"></el-option>
                        <el-option label="24 Months" :value="8" />
                    </el-select>
                </el-form-item>
                <el-form-item class="labels" label="Additional monthly benefit amount" v-if="UserSelection.ipBenefitBasis === 'Monthly' && UserSelection.ipAdditionalDeferredPeriod !== null" prop="ipAdditionalDeferredBenefitAmount">
                    <el-input v-model="UserSelection.ipAdditionalDeferredBenefitAmount" />
                </el-form-item>

                <el-form-item label="Benefit increase basis" v-if="UserSelection.ipIndexation !== 'Level'" prop="ipBenefitIncreaseBasis">
                    <el-select v-model="UserSelection.ipBenefitIncreaseBasis" placeholder="Please select" style="width: 186px;">
                        <el-option label="On Claim" value="OnClaim"></el-option>
                        <el-option label="Policy Inception" value="PolicyInception"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="btnNextPosition">
                    <el-button type="default" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span>Back</el-button>
                    <el-button type="success" @click.stop="GetQuotes('ruleFormUserSelection')">Next</el-button>
                </el-form-item>
            </el-form>

        </div>
        <div class="summary" v-if="Customers != null">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null">
                <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>

            <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Quality of plans</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>
    </div>
</template>
<style scoped>
    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .mainPolicyDiv {
            margin-left: -250px;
        }

        .summary {
            left: -250px;
        }
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        /*letter-spacing: -0.04em;*/
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        width: 232px;
        height: 572px;
        top: 0;
        right: 150px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .el-form--label-top .el-form-item__label {
        float: none;
        display: inline-block;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #0F172A;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    /*.el-form-item__label {
            font-family: 'Inter' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 24px !important;
            padding: 0px !important;
        }*/

    .el-input__inner {
        color: black;
    }

    /*.el-progress__text {
            display: none !important;
        }

        .el-progress-bar__inner {
            padding-top: 5px;
            color: #1E228C
        }
    */
    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .clientDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
    }

    /*.el-progress--without-text {
            display: none !important;
        }*/

    .el-input {
        width: 366px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-button {
        display: inline !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        border-radius: 8px !important;
        background: #059669 !important;
        border-color: #059669 !important;
    }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669 !important;
        border-radius: 8px !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px !important;
        /*border-color: #E7EAEE !important;*/
        font-style: normal !important;
        color: #A0ABBB !important;
    }

    .el-button--success {
        color: #fff !important;
        background: #059669 !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px !important;
        /*border-color: #E7EAEE !important;*/
        font-style: normal !important;
    }

    .btnParaCover {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        position: static;
        width: 367px;
        height: 44px;
        left: 82px;
        top: 935px;
    }

    .datePickerField {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        height: 48px;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .mainPolicyDiv {
        padding: 82px;
        position: absolute;
        width: 531px;
        left: 0;
        top: 0;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .Title {
        width: 366px;
        height: 20px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .TitleInputs {
        width: 166px;
        height: 48px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .labels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        color: #0F172A;
    }

    .input {
        padding: 10px 12px 10px 16px;
        width: 366px !important;
        height: 48px !important;
        left: 82px;
        top: 229px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 56px;
        display: flex;
        align-items: center;
    }

    .DropdownControl {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        position: absolute;
        width: 166px !important;
        height: 44px !important;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .btnNext {
        /*display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            text-align: right;
            width: 87px;
            height: 44px;
            left: 270px;
            top: 0px;*/
        /* Success/600 */
        /*background: #059669;
            border-radius: 8px;*/
        /**/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 167px;
        height: 44px;
        left: 200px;
        top: 0px;
        background: #059669;
        border-radius: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 14px;
        float: right;
    }
</style>
<script>
    import $ from 'jquery';
    import helper from './HelperMethods.vue';
    export default
        {
            components: {},
            name: 'PolicyDetails',
            props: ["userSelection", "userDetails", "templateSet"],
            watch: {
                'UserSelection.policyTerm': function (newValue) {
                    if (newValue !== null)
                        this.UserSelection.toAge = null;
                },
                'UserSelection.toAge': function (newValue) {
                    if (newValue !== null)
                        this.UserSelection.policyTerm = null;
                },
                "UserSelection.ipInitialDeferredPeriod": function (newValue) {
                    if (this.UserSelection.ipAdditionalDeferredPeriod <= newValue) {
                        this.UserSelection.ipAdditionalDeferredPeriod = null;
                    }
                },
                "UserSelection.selectedCustomerType": function () {
                    if (this.UserSelection.selectedCustomerType == 0 && this.Customers !== null && this.Customers !== undefined) {
                        this.PrimaryClientGender = this.Customers.life1Gender;
                        this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;

                        this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                        this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                        this.UserSelection.primaryClient.personEmployedStatus = this.Customers.life1EmploymentStatus;
                        this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                        this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                        this.UserSelection.primaryClient.title = this.Customers.life1Title;
                        this.UserSelection.primaryClient.occupation = this.Customers.life1Occupation;
                    }
                    else if (this.UserSelection.selectedCustomerType == 1 && this.Customers !== null && this.Customers !== undefined) {
                        this.PrimaryClientGender = this.Customers.life2Gender;
                        this.PrimaryClientSmokerStatus = this.Customers.life2SmokingStatus;

                        this.UserSelection.primaryClient.dateOfBirth = this.Customers.life2DateOfBirth;
                        this.UserSelection.primaryClient.forename = this.Customers.life2FirstName;
                        this.UserSelection.primaryClient.personEmployedStatus = this.Customers.life2EmploymentStatus;
                        this.UserSelection.primaryClient.salary = this.Customers.life2Salary;
                        this.UserSelection.primaryClient.surname = this.Customers.life2LastName;
                        this.UserSelection.primaryClient.title = this.Customers.life2Title;
                        this.UserSelection.primaryClient.occupation = this.Customers.life2Occupation;
                    }
                },
            },
            data: function () {

                var thisControl = this;
                return {
                    helper: helper,
                    IsGettingQuotes: false,
                    Customers: [],
                    ruleFormUserSelection: {
                        selectedCustomerType: '',
                        policyTerm: null,
                        toAge: null,
                        ipBenefitBasis: '',
                        ipBenefitAmount: '',
                        ipInitialDeferredPeriod: '',
                        ipAdditionalDeferredPeriod: '',
                        ipAdditionalDeferredBenefitAmount: null,
                        ipIndexation: '',
                        ipBenefitIncreaseBasis: '',
                    },
                    rules: {
                        selectedCustomerType: [
                            { required: true, message: 'Please select customer type', trigger: 'change' }
                        ],
                        policyTerm: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                        if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                            callback(new Error("Required if to age is not supplied."))

                                        }
                                    if (value !== null && value !== "" && typeof (value) !== "number") {
                                        callback(new Error("Term length must be a number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        toAge: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                        if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                            callback(new Error("Required if term length is not supplied."))

                                        }
                                    if (value !== null && value !== "" && typeof (value) !== "number") {
                                        callback(new Error("To age must be a number."))
                                    }
                                    callback();//all good here
                                },
                                trigger: 'change'
                            }
                        ],
                        ipBenefitBasis: [
                            { required: true, message: 'Please select Benefit basis', trigger: 'change' }
                        ],
                        ipBenefitAmount: [
                            { required: true, message: 'Please input Monthly benefit amount', trigger: 'change' },
                            //{ min: 5, max: 99999.99, message: 'Monthly benefit amount should be 5 to 99999.99', trigger: 'change' }
                            //{  type: 'number', message: 'Monthly benefit amount should be 5 to 99999.99', trigger: 'change' }
                        ],
                        ipInitialDeferredPeriod: [
                            { required: true, message: 'Please select Initial deferred period', trigger: 'change' }
                        ],
                        ipAdditionalDeferredBenefitAmount: [
                            {
                                validator: function (rule, value, callback) {
                                    if (thisControl.UserSelection.ipBenefitBasis === "Monthly")
                                        if (thisControl.UserSelection.ipInitialDeferredPeriod !== null) {
                                            if (!isFinite(value) || value <= 0) {
                                                callback(new Error("You must supply an additional benefit amount for the additional deferred period."))
                                            }
                                        }
                                    callback();//all good here
                                },
                                trigger: 'blur'
                            }
                        ],
                        ipIndexation: [
                            { required: true, message: 'Please select Indexation', trigger: 'change' }
                        ],
                        ipBenefitIncreaseBasis: [
                            { required: true, message: 'Please select Benefit increase basis', trigger: 'change' }
                        ],
                    }
                }
            },
            created: function () {
                this.UserSelection.ipCommissionType = this.UserDetails.commissionType;
                this.UserSelection.ipIndemnityRequired = this.UserDetails.indemnityRequired;
                this.UserSelection.ipCommissionPercentageRequired = this.UserDetails.commissionPercentageRequired;

                if (this.UserSelection.primaryClient === null)
                    this.UserSelection.primaryClient = { classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed' };

                if (this.UserSelection.children === null) {
                    this.UserSelection.children = [];
                    this.UserSelection.childNames = [];
                }


                if (this.UserSelection.selectedCustomerType === null) {
                    this.UserSelection.primaryClient = { classification: "", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed'};
                    this.PrimaryClientGender = '';
                    this.PolicyType = '';
                }
                else
                    this.UserSelection.selectedCustomerType = parseInt(this.UserSelection.selectedCustomerType);

                this.GetCustomer();

                //this.UserSelection = [];
                //if (this.UserSelection === null || this.UserSelection.length === 0) {
                //    this.UserSelection = {
                //        policyTerm: '', ipBenefitAmount: '', toAge: '', ipBenefitBasis: '', ipInitialDeferredPeriod: '', ipAdditionalDeferredPeriod: '', ipIndexation: '',
                //    };
                //}

                /*if (this.ExpressTemplate !== null) {
                    var oldValues = thisControl.UserSelection.customFields;
                    var newValues = {};

                    for (var customFieldsIndex = 0; customFieldsIndex < this.ExpressTemplate.customFields.length; customFieldsIndex++) {
                        var fieldName = this.ExpressTemplate.customFields[customFieldsIndex].displayQuestion;
                        if (typeof (oldValues[fieldName]) === 'string') {
                            newValues[fieldName] = oldValues[fieldName];
                        }
                        else {
                            newValues[fieldName] = this.ExpressTemplate.customFields[customFieldsIndex].options[0]
                        }
                    }
                    thisControl.UserSelection.customFields = newValues;
                }*/
            },
            mounted: function () {
                this.UserSelection.pgpLinkedAnalysisReference  = null;
                this.UserSelection.umeLinkedAnalysisReference  = null;
            },
            methods:
            {
                GetQuotes: function (formName) {
                    var thisControl = this;
                    this.$refs[formName].validate(function (valid)
                    {
                        if (valid) {
                            thisControl.$emit("tool-updated", 'IPPro - Pricing');
                            thisControl.$emit('policy-details-added');
                        } else {
                            alert("Please correct errors before continuing.")
                        }
                    });
                },
                CanProceed: function () {
                    return this.UserSelection.policyTerm > 0 && this.UserSelection.amountAssured > 0;
                },
                GetAge: function (dateString) {
                    var today = new Date();
                    var birthDate = new Date(dateString);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }
                    return age;
                },
                GetYoungestChildAge: function () {
                    var thisControl = this;
                    if (thisControl.UserSelection.children.length > 0) {
                        const thisYear = new Date().getFullYear()
                        return thisControl.UserSelection.children.reduce((res, child) => {
                            const age = (child.yearOfDeath || thisYear) - child.yearOfBirth;
                            return age < res.age ? { child, age } : res;
                        }, { child: null, age: 0 }).child;
                    }
                },
                GetOldestChildAge: function () {
                    var thisControl = this;
                    if (thisControl.UserSelection.children.length > 0) {
                        const thisYear = new Date().getFullYear()
                        return thisControl.UserSelection.children.reduce((res, child) => {
                            const age = (child.yearOfDeath || thisYear) - child.yearOfBirth;
                            return age > res.age ? { child, age } : res;
                        }, { child: null, age: 0 }).child;
                    }
                },
                GetCustomer: function () {
                    var thisControl = this;
                    this.Customers = null;

                    $.ajax({
                        type: 'GET',
                        url: '/Customer/' + thisControl.UserSelection.customerReference,
                        data: {},
                        success: function (customer) {
                            if (customer.customerReference === null)
                                customer.customerReference = thisControl.UserSelection.customerReference;

                            thisControl.Customers = customer;

                            if (thisControl.Customers.life1DateOfBirth !== null)
                                thisControl.Customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                            if (thisControl.Customers.life2DateOfBirth !== null)
                                thisControl.Customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                            if (!customer.isLifeTwo)
                                thisControl.UserSelection.selectedCustomerType = 0;
                        },
                        error: function (xhr) {
                            if (xhr.status == 401) {
                                window.location = "./Api/signin";
                                return;
                            }
                            thisControl.$message.error("Error updating customer: " + xhr.responseText);
                        }
                    });

                    return this.Customers;
                },
                CheckValidation: function () {
                    if ((this.UserSelection.policyTerm === null || this.UserSelection.policyTerm === "") && (this.UserSelection.toAge === null || this.UserSelection.toAge === ""))
                        return true;

                    if (this.UserSelection.ipBenefitBasis === null || this.UserSelection.ipBenefitBasis === "")
                        return true;

                    if (this.UserSelection.ipIndexation === null || this.UserSelection.ipIndexation === "")
                        return true;

                    if (this.UserSelection.ipInitialDeferredPeriod === null || this.UserSelection.ipInitialDeferredPeriod === "")
                        return true;

                    if (this.UserSelection.ipBenefitBasis === "Monthly") {

                        if (this.UserSelection.ipBenefitAmount === null || this.UserSelection.ipBenefitAmount === "" || this.UserSelection.ipBenefitAmount === "0")
                            return true;

                        //if (this.UserSelection.ipAdditionalDeferredPeriod === null || this.UserSelection.ipAdditionalDeferredPeriod === "")
                        //    return true;

                    }

                    if (this.UserSelection.ipIndexation === "RPI" || this.UserSelection.ipIndexation === "Three" || this.UserSelection.ipIndexation === "Five") {
                        if (this.UserSelection.ipBenefitIncreaseBasis === null || this.UserSelection.ipBenefitIncreaseBasis === "")
                            return true;
                    }

                    return false;
                },
            },
            computed: {
                UserSelection: function() { return this.userSelection; },
                UserDetails: function() { return this.userDetails; },
                TemplateSet: function() { return this.templateSet; },
                ExpressTemplate: {
                    get: function () {
                        var thisControl = this;
                        if (thisControl.TemplateSet !== null) {
                            var templates = thisControl.TemplateSet.toolTemplates.filter(function (t) {
                                return t.toolKey === thisControl.UserSelection.tool.toolReference;
                            })
                            if (templates.length > 0)
                                return templates[0];
                        }
                        return null;

                    }
                },
                ExpressCustomFields: {
                    get: function () {
                        if (this.ExpressTemplate === null)
                            return [];
                        if (this.ExpressTemplate.customFields === null) {
                            return [];
                        }
                        return this.ExpressTemplate.customFields;
                    }
                },
                ShouldAskForChildren: {
                    get: function () {
                        return this.UserSelection.features.some(function (f) { return f.requiresChildrensDetails === true; });
                    }
                },
                PrimaryClientGender: {
                    get: function () {
                        if (this.UserSelection.primaryClient.classification.startsWith("Male"))
                            return "Male";
                        else if (this.UserSelection.primaryClient.classification.startsWith("Female"))
                            return "Female";
                        else
                            return "";
                    },
                    set: function (value) {
                        this.UserSelection.primaryClient.classification = value + this.PrimaryClientSmokerStatus
                    }
                },
                PrimaryClientSmokerStatus: {
                    get: function () {
                        return this.UserSelection.primaryClient.classification.replace(this.PrimaryClientGender, "");
                    },
                    set: function (value) {
                        this.UserSelection.primaryClient.classification = this.PrimaryClientGender + value;
                    }
                },
                SecondaryClientGender: {
                    get: function () {
                        if (this.UserSelection.secondaryClient.classification.startsWith("Male"))
                            return "Male";
                        return "Female";
                    },
                    set: function (value) {
                        this.UserSelection.secondaryClient.classification = value + this.SecondaryClientSmokerStatus
                    }
                },
                SecondaryClientSmokerStatus: {
                    get: function () {
                        return this.UserSelection.secondaryClient.classification.replace(this.SecondaryClientGender, "")
                    },
                    set: function (value) {
                        this.UserSelection.secondaryClient.classification = this.SecondaryClientGender + value;
                    }
                },
                PolicyType:
                {
                    get: function () {
                        return this.UserSelection.secondaryClient === null ? "Single" : "Joint";
                    },
                    set: function (value) {
                        if (value == "Joint") {
                            this.UserSelection.secondaryClient = { classification: "Male", dateOfBirth: this.UserSelection.primaryClient.dateOfBirth }
                        }
                        else {
                            this.UserSelection.secondaryClient = null;
                        }
                    }
                },
            }
        }</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
